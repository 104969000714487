import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 accounting-tab-content p-0 px-3" }
const _hoisted_2 = { class: "grid p-0 m-0 justify-content-center" }
const _hoisted_3 = { class: "col-12 lg:col-11 p-0 grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttachmentRecords = _resolveComponent("AttachmentRecords")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      class: "col-12 border-round-3xl tab-card-content p-0",
      style: { minHeight: '63vh'}
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AttachmentRecords, {
              isLogHidden: false,
              hostFile: "AP",
              record: _ctx.getActiveOrderTab(_ctx.id),
              showMail: true,
              showTotalAttachments: false,
              onOnDeleted: _ctx.handleDeleted,
              onOnSaved: _ctx.handleSaved
            }, null, 8, ["record", "onOnDeleted", "onOnSaved"])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}